<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          next-button-text="Siguiente"
          back-button-text="Atrás"
          finish-button-text="Finalizar"
          class="steps-transparent mb-3"
          @on-complete="saveData"
        >
          <!-- account detail tab -->
          <tab-content
            title="Envio RFI"
            icon="feather icon-folder"
            :before-change="validEstablecer"
          >
            <envio :edit="false" ref="envio" />
          </tab-content>

          <!-- personal details -->
          <tab-content
            title="Detalle RFI"
            icon="feather icon-clipboard"
            :before-change="validDetalle"
          >
            <detalle :edit="false" ref="detalle" />
          </tab-content>

          <!-- address -->
          <tab-content title="Respuesta" icon="feather icon-user" :before-change="validRespuesta">
            <respuesta :edit="false" ref="respuesta" />
          </tab-content>

          <!-- social link -->
          <tab-content
            title="Definición Fechas"
            icon="feather icon-calendar"
            :before-change="validDefinicion"
          >
            <definicion :edit="false" ref="definicion" />
          </tab-content>
          <tab-content
            title="Analisis"
            icon="feather icon-clipboard"
            :before-change="validAnalisis"
          >
            <analisis :edit="false" ref="analisis" />
          </tab-content>
          <!-- <tab-content title="Archivos" icon="feather icon-upload">
            <archivos :edit="false" ref="archivos" />
          </tab-content> -->
        </form-wizard>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Envio from './steps/envio.vue'
import detalle from './steps/detalle.vue'
import respuesta from './steps/respuesta.vue'
import definicion from './steps/definicion.vue'
import analisis from './steps/analisis.vue'
// import archivos from './steps/archivos.vue'
import RfiService from '@/services/RfiService'
import FilesRfiService from '@/services/FilesRfiService'
import vSelect from 'vue-select'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      tabIndex: 0,
      id_espe: null,
      leadTime: '',
      showLoading: false,
      dataItems: {
        code: '',
        version: '',
        id_transmittal_aconex: '',
        description: '',
        detail: '',
        reference: '',
        comments_transmittal_JOT: '',
        id_response_aconex: '',
        response_detail: '',
        comments_response_JOT: '',
        status_send: '',
        comments_dates_JOT: '',
        status_dates: '',
        date_identified: null,
        date_required: null,
        date_respond: null,
        date_send: null,
        date_released: null,
        time_anticipation: null,
        project_id: null,
        speciality_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null,
        owner_id: null,
        co_owner_id: null,
        user_id: null,
        impact_cost_scope: '',
        impact_cost_element: '',
        impact_time: '',
        impact_quality: ''
      }
    }
  },
  components: {
    Envio,
    detalle,
    // archivos,
    definicion,
    respuesta,
    analisis,
    FormWizard,
    TabContent,
    vSelect
  },
  mounted() {
    this.dataItems.project_id = parseInt(this.$route.params.id)
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.dataItems.user_id = userData.id
  },
  methods: {
    formSubmitted() {
      console.log('aqui?')
    },
    cancel() {
      this.$router.push('/restricciones')
    },
    selectSpe(id) {
      this.$refs.detalle.setDat(id)
      this.$refs.detalle.resetForm()
    },
    lead(led) {
      this.$refs.detalle.setLead(led)
    },
    validEstablecer() {
      return new Promise((resolve, reject) => {
        // var validate = null
        this.$refs.envio.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.code = this.$refs.envio.items.code
            this.dataItems.version = this.$refs.envio.items.version
            this.dataItems.id_transmittal_aconex = this.$refs.envio.items.id_transmittal_aconex
            this.dataItems.description = this.$refs.envio.items.description
            this.dataItems.detail = this.$refs.envio.items.detail
            this.dataItems.reference = this.$refs.envio.items.reference            
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDetalle() {
      return new Promise((resolve, reject) => {
        this.$refs.detalle.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.owner_id = this.$refs.detalle.items.owner_id
            this.dataItems.speciality_id = this.$refs.detalle.items.speciality_id
            this.dataItems.location_1_id = this.$refs.detalle.items.location_1_id
            this.dataItems.location_2_id = this.$refs.detalle.items.location_2_id
            this.dataItems.location_3_id = this.$refs.detalle.items.location_3_id
            this.dataItems.location_4_id = this.$refs.detalle.items.location_4_id
            this.dataItems.comments_transmittal_JOT = this.$refs.detalle.items.comments_transmittal_JOT
            this.dataItems.status_send = this.$refs.detalle.items.status_send
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validRespuesta() {
      return new Promise((resolve, reject) => {
        this.$refs.respuesta.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.id_response_aconex = this.$refs.respuesta.items.id_response_aconex
            this.dataItems.date_respond = this.$refs.respuesta.items.date_respond
            this.dataItems.response_detail = this.$refs.respuesta.items.response_detail
            this.dataItems.comments_response_JOT = this.$refs.respuesta.items.comments_response_JOT
            this.dataItems.co_owner_id = this.$refs.respuesta.items.co_owner_id
            this.dataItems.date_released = this.$refs.respuesta.items.date_released
            this.dataItems.status_respond = this.$refs.respuesta.items.status_respond
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDefinicion() {
      console.log('assad')
      console.log('this.$refs.definicion.items', this.$refs.definicion.items)
      return new Promise((resolve, reject) => {
        this.$refs.definicion.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.date_identified = new Date(this.$refs.definicion.items.date_identified)
            this.dataItems.date_required = new Date(this.$refs.definicion.items.date_required)
            this.dataItems.date_send = new Date(this.$refs.definicion.items.date_send)
            this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
            this.dataItems.comments_dates_JOT = this.$refs.definicion.items.comments_dates_JOT
            this.dataItems.status_dates = this.$refs.definicion.items.status_dates
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validAnalisis() {
      console.log('assad')
      console.log('this.$refs.analisis.items', this.$refs.analisis.items)
      return new Promise((resolve, reject) => {
        this.$refs.analisis.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.impact_cost_scope = this.$refs.analisis.items.impact_cost_scope
            this.dataItems.impact_cost_element = this.$refs.analisis.items.impact_cost_element
            this.dataItems.impact_time = this.$refs.analisis.items.impact_time
            this.dataItems.impact_quality = this.$refs.analisis.items.impact_quality
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async saveData() {
      this.showLoading = true
      console.log('this.dataItems',this.dataItems)
      let archivos = this.$refs.envio.records
      let archivosAns = this.$refs.respuesta.records
      Array.prototype.push.apply(archivos,archivosAns);
      console.log('archivos', archivos)
      let detalle = new FormData()
      const save = await RfiService.saveRfi(this.dataItems, this.$store)
      if (save.status) {
        if (archivos.length > 0) {
          let resp = null
          for (let index = 0; index < archivos.length; index++) {
            const element = archivos[index]
            detalle.append('description', element.description)
            detalle.append('file', element.file)
            detalle.append('rfi_id', save.data.id)
            detalle.append('type_attach', element.type_attach)
            resp = await FilesRfiService.saveFile(detalle, this.$store)
            if (resp.status) {
              detalle.delete('description')
              detalle.delete('file')
              detalle.delete('rfi_id')
              detalle.delete('type_attach')
            }
          }
          if (resp != null) {
            if (resp.status) {
              this.$swal({
                title: 'Regitrado',
                text: 'Los detalle han sido registrados con exito.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              // this.$router.push('/restricciones')
            } else {
              this.$swal({
                title: 'Error!',
                text: 'Ocurrió un error al registrar los archivos.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: 'Ocurrió un error al guardar los archivos.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
        this.$swal({
          title: 'Regitrado',
          text: 'Los detalle han sido registrados con exito.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        this.$router.push('/rfi')
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Ocurrió un error al registrar los detalle de los formularios.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
      this.showLoading = false
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
